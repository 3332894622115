const descriptionInput = () => {
    const wrappers = document.querySelectorAll('.input-icons')

    if (wrappers) {

        [...wrappers].forEach(wrapper => {
            const iconBtn = wrapper.querySelector('.icon-input');
            const descriptionContainer = wrapper.querySelector('.description-input');

            mouseEvent(iconBtn, descriptionContainer);

            iconBtn.addEventListener('click', function () {
                if (descriptionContainer.classList.contains('d-none')) {
                    descriptionContainer.classList.remove('d-none')
                } else {
                    descriptionContainer.classList.add('d-none');
                }
            })
        })
    }
}

const mouseEvent = (iconBtn, descriptionContainer) => {

    let timer;

    descriptionContainer.addEventListener('mouseleave', function () {
        timer = setTimeout(function () {
            descriptionContainer.classList.add('d-none')
        }, 1500);
    })
    descriptionContainer.addEventListener('mouseenter', function () {
        clearTimeout(timer)
    })

    iconBtn.addEventListener('mouseleave', function () {
        timer = setTimeout(function () {
            descriptionContainer.classList.add('d-none')
        }, 1500);
    })
    iconBtn.addEventListener('mouseenter', function () {
        clearTimeout(timer)
    })

}

export default descriptionInput;