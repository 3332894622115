import customSelect from "custom-select/src";
import {
  cardCheck,
  currencyFormat,
  currencyFormatCustom,
  debounceWithCache,
  formatCurrency,
  getCompanyId,
  inlineInputAddHint,
  inlineInputAddInfoHint,
  inlineInputRemoveHint,
  inputDebounced,
  mailValidate
} from "../helpers";
import api from "./ApiController";
import Cache from "./CacheController";

const cache = new Cache();

export const getAmountInput = () => getWrapper(getTypeOfTransaction()).querySelector('.inline-input.summ');

const getAmountValue = () => parseFloat(getAmountInput().value.replaceAll(' ', '') || 0) * 100;

const getSelectorOptions = (selector) => selector.querySelectorAll('option');

const getOptionByCurrency = (selector, currency) => {
  const option = [...getSelectorOptions(selector)].filter(option => option.dataset.currency === currency);
  if (option.length === 0) return;
  return option[0];
};

const convertOptions = {
  'RUB': 'USD',
  'USD': 'RUB',
}

const getOppositeSelector = (selectors, key) => {
  if (key === 0) return selectors[1];
  if (key === 1) return selectors[0];
}

const convertOptionOfSelector = (selector, value) => {
  return convertOptions[getActiveCurrency(selector, value)]
}

const getOppositeValueOfSelector = (selectors, key, account_id = null) => {
  const opposite = getOppositeSelector(selectors, key);
  if (!account_id) account_id = selectors[key].value;
  return getOptionByCurrency(selectors[key], convertOptionOfSelector(opposite, account_id)).value;
}


const setSelectorValue = (selector, value) => {
  selector.customSelect.value = value;
  selector.value = value;
  // blockSelection(selector)
}

const EXCLUDE_CLASS_LIST = [
  'mass-instructions-download',
  'no-convert'
];

const main = () => {
  let exit = false;
  EXCLUDE_CLASS_LIST.forEach(name => exit = document.querySelector('.' + name) || exit);
  if (exit) return;
  const account = sessionStorage.getItem('account_id');
  const selectors = getSelectors();

  const button = document.querySelector('.replenishment__button');

  // selectors check
  if (selectors.length > 1) { // convert
    selectors.forEach((selector, key) => {
      getActiveCurrency(selector, account);

      if (key === 0) generateSelector(selector, account);
      if (key === 1) generateSelector(selector, getOppositeValueOfSelector(selectors, key, account));

      selector.addEventListener('change', () => {
        const selectors = getSelectors();
        setSelectorValue(getOppositeSelector(selectors, key), getOppositeValueOfSelector(selectors, key))
        getConvert();
        changeCurrencyDouble();
      })
      const amountInput = getAmountInput();
      amountInput.addEventListener('input', () => getConvert());
    });
    changeCurrencyDouble();
  } else if (selectors.length === 1) { //  w2w w2c ltd2p ltd2c
    const amountInput = getAmountInput();
    const selector = selectors[0];
    if (getTypeOfTransaction() !== 'card') generateSelector(selector, account);
    else customSelect(selector)
    const currency = getActiveCurrency(selector, account);
    if (getTypeOfTransaction() !== 'card') changeCurrencySingle(currency);
    //------------------//
    changeSwitch();
    //------------------//
    selector.addEventListener('change', () => {
      const currency = getActiveCurrency(selector, selector.value);
      changeCurrencySingle(currency);
      checkCommission();
      changeSwitch();
    })
    singleSelectorChecker();
    amountInput.addEventListener('input', () => checkCommission());
  } else { // card2w
    const amountInput = getAmountInput();
    amountInput.addEventListener('input', () => checkC2w());
    singleSelectorChecker();
  }

  // document.querySelector('form').addEventListener('submit', e => e.preventDefault());
  button.addEventListener('click', (e) => submitDisabler(e));
}

const changeSwitch = () => {
  const wrapper = document.querySelector('.destination-section .switch');
  if (!wrapper) return;
  const currency = getActiveCurrency(getSelector());
  const typeInput = document.querySelector('.wallet-info input.wallet-type');
  const switchItem = wrapper.querySelectorAll('.switch__item');
  if (currency !== 'USD') {
    checkFirstInput();
    const bscInput = document.querySelector('.wallet-info .wallet-bsc');
    bscInput.classList.add('d-none');
    getInputByType('wallet').classList.remove('d-none');
    const caption = document.querySelector('.wallet-info .inline-input__label');
    caption.innerText = 'Номер кошелька';
    typeInput.value = 0; // Если с
    return wrapper.classList.add('d-none')
  } else {
    wrapper.classList.remove('d-none')
    switchItem.forEach(item => {
      if (cache.exist('wallet_type')) {
        const typeInput = document.querySelector('.wallet-info input.wallet-type');
        typeInput.value = cache.get('wallet_type');
        changeSwitchItem(item, cache.get('wallet_type'))
      }
    })}


  switchItem.forEach((item, key) => {
    const typeInput = document.querySelector('.wallet-info input.wallet-type');
    if (!typeInput) return;
    if (item.classList.contains('switch__item-active')) {
      typeInput.value = key;
    }
    item.addEventListener('click', () => {
      changeSwitchItem(item, key);
    })
  })
}

const changeSwitchItem = (item, key) => {
  const wrapper = document.querySelector('.destination-section .switch');
  if (!wrapper) return;
  const switchItem = wrapper.querySelectorAll('.switch__item');
  const bg = wrapper.querySelector('.switch__bg');
  const bscInput = document.querySelector('.wallet-info .wallet-bsc');
  if (!bscInput) return;
  const caption = document.querySelector('.wallet-info .inline-input__label');
  if (!caption) return;
  const typeInput = document.querySelector('.wallet-info input.wallet-type');
  if (!typeInput) return;
  const totalSum = document.querySelector('.total-sum');
  if (!totalSum) return;
  if (item.classList.contains('switch__item-active')) return;
  if (key === 0) { //wallet
    switchItem[1].classList.remove('switch__item-active');
    switchItem[0].classList.add('switch__item-active');
    bg.style.left = 0;
    bg.style.right = '50%';
    bscInput.classList.add('d-none');
    getInputByType('wallet').classList.remove('d-none');
    caption.innerText = 'Номер кошелька';
    checkFirstInput();
  } else { //bsc
    switchItem[0].classList.remove('switch__item-active');
    switchItem[1].classList.add('switch__item-active');
    bg.style.right = 0;
    bg.style.left = '50%';
    bscInput.classList.remove('d-none');
    getInputByType('wallet').classList.add('d-none');
    caption.innerText = 'Адрес';

    totalSum.innerText = '0 USDT';
  }
  if (item.classList.contains('switch__item-active')) {
    typeInput.value = key;
    cache.set('wallet_type', key);
  }
  inlineInputRemoveHint(getInputWrapper());
  checkCommission();
}

const getC2WCommissionName = () => getOnlyDigits(getAmountInput().value);

export const checkC2w = () => {
  if (cache.exist('commission_fetch') && cache.get('commission_fetch')) return;
  const name = getC2WCommissionName();
  if (cache.exist(name)) {
    commissionInsert(cache.get(name));
  } else {
    const body = prepareCommissionBody()
    debounceWithCache(cache, name, () => getCommission(body), 1000);
  }
}

export const submitDisabler = (e) => {
  if (cache.exist('convert_fetch') && cache.get('convert_fetch')) return e.preventDefault();
  if (cache.exist('commission_fetch') && cache.get('commission_fetch')) return e.preventDefault();
  if (cache.exist('phone_fetch') && cache.get('phone_fetch')) return e.preventDefault();
  const type = getTypeOfTransaction();

  if (type === 'wallet') {

    const input = getOnlyDigits(getInput().value);
    if (input.length < 11) return e.preventDefault();
    const name = getPhoneCacheName();
    if (!cache.exist(name)) return e.preventDefault();

    const res = cache.get(name);
    if (!res.user_exists) {
      activeNoUserModal();
      return e.preventDefault();
    }

    if (!res.account_exists) return e.preventDefault();
    if (!cache.exist(getCommissionCacheName())) return e.preventDefault();
    const commissionCache = cache.get(getCommissionCacheName());
    if (commissionCache.status === 'Error' || commissionCache.payload.message !== '') return e.preventDefault();


  }

  if (type === 'bsc') {
    if (getOnlyDigits(getInput().value).length < 5) return e.preventDefault();
    if (!cache.exist(getCommissionCacheName())) return e.preventDefault();
    const commissionCache = cache.get(getCommissionCacheName());
    if (commissionCache.status === 'Error' || commissionCache.payload.message !== '') return e.preventDefault();

  }

  if (type === 'card') {
    // console.info({
    //   exist: cache.exist(getCommissionCacheName()),
    //   message: cache.get(getCommissionCacheName()).status === 'Error' || cache.get(getCommissionCacheName()).payload.message != '',
    //   input: getOnlyDigits(getInput().value).length < 13,
    //   card: cardCheck(input)
    // })
    if (!cache.exist(getCommissionCacheName())) return e.preventDefault();
    const commissionCache = cache.get(getCommissionCacheName());
    if (commissionCache.status === 'Error' || commissionCache.payload.message !== '') return e.preventDefault();
    const input = getOnlyDigits(getInput().value);
    if (input.length < 13) return e.preventDefault();
    if (!cardCheck(input)) return e.preventDefault();
  }
  if (type === 'card2w') {
    if (!cache.exist(getC2WCommissionName())) return e.preventDefault();
    const commissionCache = cache.get(getC2WCommissionName());
    if (commissionCache.status === 'Error' || commissionCache.payload.message !== '') return e.preventDefault();

    // fix https://gitlab.vidipay.io/bugzilla/show_bug.cgi?id=221
    /*const input = getOnlyDigits(getInput().value);
    if (input.length < 13) { return e.preventDefault() }
    if (!cardCheck(input)) return e.preventDefault();
    if (!expIsValid(getExp().value)) return e.preventDefault();
    if (!cscIsValid(getCsc().value)) return e.preventDefault();*/
  }
  if (type === 'covert') {
    if (!cache.exist(getConvertName())) return e.preventDefault();
    const sumInput = document.querySelector('.summ');
    if(!sumInput || sumInput.value.length === 0) return e.preventDefault();

    const convertCache = cache.get(getConvertName());
    if (convertCache.status === 'Error' || convertCache.payload.message !== '') return e.preventDefault();
    if (!getOnlyDigits(document.querySelector('.commission-sum').innerText)) return e.preventDefault();
  }
}
const getPhoneCacheName = () => getOnlyDigits(getInput().value) + getActiveCurrency(getSelector());

const activeNoUserModal = () => {
  const modalNoWallet = document.querySelector('.blur-portal__wallet-not-found');
  const phoneNumberModal = document.querySelector('.blur-portal__wallet-not-found .desc-item .desc-value');
  const phoneNumberModalInput = document.querySelector('.blur-portal__wallet-not-found .desc-item .hidden-phone-input');
  modalNoWallet.classList.add("active");
  setTimeout(() => modalNoWallet.classList.add("animate"), 16);
  phoneNumberModal.innerText = getInput().value;
  phoneNumberModalInput.value = getInput().value;

  const submitButton = modalNoWallet.querySelector('.btn__default-primary-rounded');
  const emailInputWrapper = modalNoWallet.querySelector('.inline-input__wrapper-modal-mail');
  const emailInput = emailInputWrapper.querySelector('.email-input');

  emailInput.addEventListener('input', function (e) {
    if (e.target.value.length > 0 && mailValidate(e.target.value)) {
      inlineInputRemoveHint(emailInputWrapper);
      submitButton.disabled = false;

    } else {

      setTimeout(() => {
        if (!mailValidate(e.target.value)) {
          inlineInputRemoveHint(emailInputWrapper);
          inlineInputAddHint(emailInputWrapper, 'Неверный формат почты')
        }
      }, 2000)
      submitButton.disabled = true
    }
  })

  submitButton.addEventListener('click', function (e) {
    if (emailInput.value.length === 0) {
      inlineInputAddHint(emailInputWrapper, 'Это поле обязательно для заполнения')
      e.preventDefault();
    }
  })

}

const getConvert = () => {
  if (cache.exist('convert_fetch') && cache.get('convert_fetch')) return;
  const name = getConvertName();
  const body = generateConvertBody();
  if (cache.exist(name)) convertInsert(cache.get(name), getCurrencies());
  else debounceWithCache(cache, name, () => requestConvert(body), 1000);
}

const requestConvert = async (body) => {
  const loader = getCommissionLoader();
  if (loader) {
    loader.classList.add('modal__loader-enabled');
  }
  getAmountInput().disabled = true;

  try {
    cache.set('convert_fetch', true);
    const res = await api.post({href: '/getConvert', body});
    if (loader) {
      loader.classList.remove('modal__loader-enabled');
    }
    getAmountInput().disabled = false;
    getAmountInput().focus();
    convertInsert(res, getCurrencies());
    cache.set('convert_fetch', false);
    return res;
  } catch (e) {
    cache.set('convert_fetch', false);
    getAmountInput().disabled = false;
    getAmountInput().focus();
    console.error(e);
    if (loader) {
      loader.classList.remove('modal__loader-enabled');
    }
    convertInsert({}, getCurrencies())
    return {};
  }
}

const checkPhone = () => {
  const phoneName = getPhoneCacheName();
  if (!cache.exist(phoneName)) return false;
  const phoneRes = cache.get(phoneName);
  return !(!phoneRes.user_exists || !phoneRes.account_exists);
}


const getWalletType = () => document.querySelector('.wallet-info input.wallet-type')?.value || -1


const getCommissionCacheName = () => getWalletType() + '|' + getAmountValue() + getOnlyDigits(getInput().value) + getActiveCurrency(getSelector());

const checkCommission = () => {
  const type = getTypeOfTransaction();
  const length = getOnlyDigits(getInput().value).length;
  if (type === 'wallet') {
    if (!checkPhone()) return commissionInsert({})
    if (length < 11) return;
  }
  if (type === 'bsc' && length < 5) return;
  if (cache.exist('commission_fetch') && cache.get('commission_fetch')) return console.info('fetching');
  const name = getCommissionCacheName();
  if (cache.exist(name)) {
    commissionInsert(cache.get(name));
  } else {
    const body = prepareCommissionBody()
    debounceWithCache(cache, name, () => getCommission(body), 1000);
  }
}

const prepareCommissionBody = () => {
  const company = getCompanyId();

  const type = getTypeOfTransaction();
  const body = {
    amount: getAmountValue(),
    input: getOnlyDigits(getInput().value),
  };

  if (type !== 'card2w' && getSelector()) {
    body.account = getSelector().value;
  }

  if (company !== 0) {
    body.type = 'company_' + type;
    body.company = company;
  } else {
    body.type = type;
  }

  return body;
}

const getCommission = async (body) => {
  const loader = getCommissionLoader();
  if (loader) {
    loader.classList.add('modal__loader-enabled')
  }


  try {
    getAmountInput().disabled = true;
    cache.set('commission_fetch', true);
    const res = await api.post({href: '/getCommission', body});
    commissionInsert(res);
    cache.set('commission_fetch', false);
    if (loader) {
      loader.classList.remove('modal__loader-enabled')
    }
    getAmountInput().disabled = false;
    getAmountInput().focus();
    return res;
  } catch (e) {
    getAmountInput().disabled = false;
    getAmountInput().focus();
    console.error('error', e);
    return {};
  }


}

const commissionInsert = (res) => {
  const {
    payload = {add_total: 0, total: 0, percent: 0, const_pay: 0, message: '', estimated: 0},
    status = 'Error'
  } = res;
  const {add_total, total, percent, const_pay, message, estimated} = payload;
  const totalSum = document.querySelector('.total-sum');
  const commissionSum = document.querySelector('.commission-sum');
  const commissionPer = document.querySelector('.commission-percent');
  const commissionConst = document.querySelector('.const-pay');
  const switchItem = document.querySelectorAll('.destination-section .switch .switch__item');
  let currency = 'RUB'
  try {
    currency = getActiveCurrency(getSelector());
  } catch (e) {
  }

  if (message || message === '') {

    inlineInputRemoveHint(getAmountWrapper());
    if (message !== '') {
      inlineInputAddHint(getAmountWrapper(), message);
    }

    if (commissionSum) commissionSum.innerText = currencyFormatCustom(0, currency);

    if (totalSum) {
      totalSum.innerText = currencyFormatCustom(0, currency);
    }

    if (commissionPer) commissionPer.innerText = '';

    if (commissionConst) commissionConst.innerText = '';
  } else {
    inlineInputRemoveHint(getAmountWrapper());
  }

  if (estimated > 0) {
    inlineInputAddInfoHint(getAmountWrapper(), 'По вашему лимиту осталось ' + currencyFormatCustom(estimated / 100, currency))
  }


  if (commissionSum && add_total) {
    commissionSum.innerText = currencyFormatCustom(add_total / 100, currency);
  }

  if (totalSum && total) {
    const totalSumText = currencyFormatCustom(total / 100, currency)

    if (switchItem.length > 0 && switchItem[1].classList.contains('switch__item-active')) {
      totalSum.innerText = totalSumText.replace('$', 'USDT');
    } else {
      totalSum.innerText = totalSumText;
    }
  }

  if (commissionPer && percent) {
    commissionPer.innerText = +percent + ' %';
  }

  if (commissionConst && const_pay) {
    commissionConst.innerText = ' + ' + currencyFormatCustom(const_pay / 100, currency);
  }
}

const checkBSCWallet = () => document.querySelector('.wallet-info .wallet-bsc').value.length > 6 ? checkCommission() : null;

export const singleSelectorChecker = () => {

  getInput(true).addEventListener('input', () => checkFirstInput());
  const bscInput = document.querySelector('.wallet-info .wallet-bsc');
  if (bscInput) bscInput.addEventListener('input', () => checkBSCWallet());
  getSelector()?.addEventListener('change', () => checkFirstInput());
}

export const checkFirstInput = () => {
  // check phone/card input
  const type = getTypeOfTransaction();
  const input = getInput();
  const value = getOnlyDigits(input.value);
  if (value.length > 12) {
    if (type === 'card' || type === 'card2w') inputCard2w(value, input);
  } else if (value.length === 11) {
    if (type === 'wallet') inputWallet(value)
  }
}

export const inputCard2w = (value, input) => {
  if (!cardCheck(value)) inputDebounced(input, () => checkCard(), 1000);
  else inlineInputRemoveHint(getInputWrapper());

}

const checkCard = () => {
  const input = getInput();
  const value = getOnlyDigits(input.value);
  const valid = cardCheck(value);
  if (!valid) inlineInputAddHint(getInputWrapper(), "Неверный номер карты");
}


const inputWallet = async (value) => {
  if (cache.exist('phone_fetch') && cache.get('phone_fetch')) return;
  const currency = getActiveCurrency(getSelector())
  const name = getPhoneCacheName();
  if (cache.exist(name)) {
    phoneInputErrorHandler(cache.get(name));
    checkCommission();
  } else {
    inlineInputRemoveHint(getInputWrapper());
    cache.set('phone_fetch', true);
    await check(value, currency);
    cache.set('phone_fetch', false);
  }
}

const check = async (phone, currency) => {
  const loader = getLoader();
  const wrapper = getInputWrapper();
  loader.classList.add('modal__loader-enabled');

  try {
    const res = await api.post({href: '/phonetest', body: {phone, currency}});
    phoneInputErrorHandler(res);
    cache.set(getPhoneCacheName(), res);
    checkCommission();
  } catch (e) {
    inlineInputAddHint(wrapper, 'Произошла ошибка')
    console.error(e);
  }

  loader.classList.remove('modal__loader-enabled');


}

const convertInsert = (res, currencies) => {
  const {
    payload = {
      exchange_rate: 0,
      total: 0,
      percent: 0,
      const_pay: 0,
      message: '',
      currency: 'RUB',
      estimated: 0,
    },
    status = 'Error'
  } = res;
  const {exchange_rate, total, message, estimated} = payload;

  const totalSum = document.querySelector('.total-sum');
  const commissionSum = document.querySelector('.commission-sum');

  if (message || message === '') {

    inlineInputRemoveHint(getAmountWrapper());
    if (message !== '') inlineInputAddHint(getAmountWrapper(), message)
    if (exchange_rate) {
      if (currencies[1] === 'VID') {
        commissionSum.innerText = currencyFormatCustom(exchange_rate, 'VID');
      } else {
        commissionSum.innerText = currencyFormatCustom(exchange_rate, 'RUB');
      }
    }

    if (totalSum && totalSum) totalSum.innerText = currencyFormatCustom(0, currencies[1]);

  } else {
    inlineInputRemoveHint(getAmountWrapper());
  }

  if (estimated > 0) {
    inlineInputAddInfoHint(getAmountWrapper(), 'По вашему лимиту осталось ' + currencyFormat(estimated / 100))
  }


  if (commissionSum && exchange_rate !== undefined) {
    if (currencies[1] === 'VID') {
      commissionSum.innerText = currencyFormatCustom(exchange_rate ? exchange_rate : 0, 'VID');
    } else {
      if (currencies[0] === 'USD') document.querySelector('.commission-wrapper .auth-property').innerText = 'Курс продажи';
      else document.querySelector('.commission-wrapper .auth-property').innerText = ' Курс покупки';
      commissionSum.innerText = currencyFormatCustom(exchange_rate ? exchange_rate : 0, 'RUB');
    }
  }

  if (totalSum && total !== undefined) {
    totalSum.innerText = currencyFormatCustom((total ? total : 0) / 100, currencies[1]);
  }

  const overallAmount = document.querySelector('.overall-amount__wrapper .overall-amount');
  if (overallAmount) {
    overallAmount.value = (+total).toFixed(0);
  }
}


export const getOnlyDigits = (string) => (string.match(/\d/g) ?? []).join("");

const changeCurrencySingle = (currency) => {
  const currencyHolder = document.querySelector('.sum-input-wrapper .rub');
  const totalSum = document.querySelector('.total-sum');
  const commissionSum = document.querySelector('.commission-sum');
  const commissionPer = document.querySelector('.commission-percent');
  const commissionConst = document.querySelector('.const-pay');
  if (currencyHolder) currencyHolder.innerText = formatCurrency(currency);
  if (totalSum) totalSum.innerText = currencyFormatCustom(getOnlyDigits(totalSum.innerText), currency);
  if (commissionSum) commissionSum.innerText = currencyFormatCustom(getOnlyDigits(commissionSum.innerText), currency);
  if (commissionPer && getOnlyDigits(totalSum.innerText) > 0) commissionPer.innerText = currencyFormatCustom(getOnlyDigits(commissionPer.innerText), currency);
  if (commissionConst && getOnlyDigits(totalSum.innerText) > 0) commissionConst.innerText = currencyFormatCustom(getOnlyDigits(commissionConst.innerText), currency);
}

const changeCurrencyDouble = () => {
  const currencies = getCurrencies();
  const currencyHolder = document.querySelector('.sum-input-wrapper .rub');
  const totalSum = document.querySelector('.total-sum');
  if (currencyHolder) currencyHolder.innerText = formatCurrency(currencies[0]);
  if (totalSum) totalSum.innerText = currencyFormatCustom(0, currencies[1]);
}


/**
 * @param {*} selector
 * @param {string} value
 */
const generateSelector = (selector, value) => customSelect(selector)[0].value = value;

/**
 * @param {*} selector
 * @param {string} value
 * */
const getActiveOption = (selector, value) => [...selector.querySelectorAll('option')].filter(option => option.value === value);

/**
 * @param {*} selector
 * @param {string} value
 * */
const getActiveCurrency = (selector, value = selector.value) => getActiveOption(selector, value)[0].dataset.currency;

const getInputByType = (type) => {
  if (type === 'wallet') return document.querySelector(".wallet-info .wallet-num");
  if (type === 'card') return document.querySelector(".wallet-info .card-num");
  if (type === 'card2w') return document.querySelector('.cc-number');
  if (type === 'bsc') return document.querySelector('.wallet-info .wallet-bsc');
}

export const getInput = (not_bsc) => {
  if (!not_bsc && getTypeOfTransaction() === 'bsc' && getActiveCurrency(getSelector()) === 'USD') return document.querySelector('.wallet-info .wallet-bsc');
  else return document.querySelector(".wallet-info .wallet-num") || document.querySelector(".wallet-info .card-num") || document.querySelector('.cc-number');
}

const getTypeOfTransaction = () => {
  if (document.querySelector(".wallet-info .wallet-num")) {
    const bsc_input = document.querySelector('.wallet-info .wallet-bsc');
    if (bsc_input && !bsc_input.classList.contains('d-none') && getActiveCurrency(getSelector()) === 'USD') {
      return 'bsc';
    }
    return 'wallet';
  }
  if (document.querySelector(".wallet-info .card-num")) return 'card';
  if (document.querySelector('.cc-number')) return 'card2w';
  if (document.querySelector('.sum-input-wrapper')) return 'covert';
}

const getInputWrapper = (type) => {
  if (!type) type = getTypeOfTransaction();
  if (type === 'card2w') return document.querySelector('.cc__wrapper .number-wrapper');
  return document.querySelector('.wallet-info .inline-input__wrapper')
}

const getWrapper = (type) => {
  if (!type) return;
  if (type !== 'card2w') return document.querySelector('.transfer__inputs');
  return document.querySelector('.replenishment-input__value-container');
}

const phoneInputErrorHandler = (res) => {
  const wrapper = getInputWrapper();
  if (!res || !res.user_exists) {
    inlineInputAddHint(wrapper, 'Неверный номер кошелька')
  } else if (!res || !res.account_exists) {
    inlineInputAddHint(wrapper, 'У пользователя не найден валютный счёт')
  } else {
    inlineInputRemoveHint(wrapper)
  }
}

const generateConvertBody = () => ({
  amount: getAmountValue(),
  from: +getSelectors()[0].value,
  to: +getSelectors()[1].value,
  company: getCompanyId(),
})

const getSelectors = () => document.querySelectorAll('.account-selector');

const getCurrencies = () => [...getSelectors()].map(selector => getActiveCurrency(selector));

const getConvertName = () => getCurrencies()[0] + getCurrencies()[1] + getAmountValue()


const getLoader = () => document.querySelector('.wallet-info .modal__loader');
const getCommissionLoader = () => document.querySelector('.commission-wrapper .modal__loader');
const getAmountWrapper = () => document.querySelector('.sum-input-wrapper');
const getSelector = () => document.querySelector('.account-selector');

export default main;