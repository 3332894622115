// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import $ from 'jquery';
import {initBootstrap} from "./bootstrap";
import phonePage, {submitButtonDisable} from "./screens/auth/phonePage";
import codePage from "./screens/auth/codePage";
import passPage from "./screens/auth/passPage";
import passChangePage from "./screens/auth/passChangePage";
import userRegisterPage from "./screens/auth/userRegisterPage";
import mainPage from "./mainPage";
import searchCompany from "./screens/company/searchCompany";
import {alertCloser, autoloadPopups} from "./helpers";
import masks from "./compenents/masks";
import transfer from "./screens/transfer/transfer";
import massTransfer from "./screens/transfer/massTransfer";
import addAcquiring from "./screens/acquiring/addAcquiring";
import {} from 'jquery-ujs';
import mainPageAcquiring from "./screens/main/mainPageAcquiring";
import modalReferral from "./modal/modal-referral";
import profileSettings from "./screens/profile/profile_settings";
import dropdownNotification from "./compenents/dropdown-notification";
import loginPagePay from "./screens/auth/loginPagePay";
import payTariff from "./screens/tariff/payTariff";
import activateAccountPage from "./screens/auth/activateAccount";
import serviceAlert from "./compenents/service-alert";
import shopPage from "./screens/shop/shop";
import descriptionInput from "./compenents/descriptionInput";
import {dropDownBlockUser} from "./landing-block/main-block-user";
import mousePopup from "./compenents/mousePopup";

document.addEventListener('DOMContentLoaded', () => {
  const pageType = document.querySelector(".auth__screen")?.getAttribute('data-page');
  const pageTypeShop = document.querySelector('.shop-body')?.getAttribute('data-page');
  const isShopPage = document.querySelector('.shop_acquiring');
  if (pageType === 'phone_input') {
    phonePage();
    submitButtonDisable();
  }
  if (pageType === 'password_input') passPage();
  if (pageType === 'password_change') passChangePage();
  if (pageType === 'user_register') userRegisterPage();
  if (pageTypeShop === 'login_pay') loginPagePay();
  if (isShopPage && window.web3Component) {
    shopPage();
  }
  shopAcquiring();
  alertCloser();
  codePage();
  mainPage();
  searchCompany();
  masks();
  transfer();
  massTransfer();
  addAcquiring();
  mainPageAcquiring();
  urlToggle();
  ultraLightSpeedButtonDisabler3000();
  throttleHrefs();
  scrollButtons();
  modalReferral();
  profileSettings();
  dropdownNotification();
  copy();
  payTariff();
  activateAccountPage();
  serviceAlert();
  descriptionInput();
  dropdown();
  dropDownBlockUser();
  autoloadPopups();
});

let active = false;
const dropdown = () => {
  const dropdown = document.querySelector('.dropdown-custom');
  if (!dropdown) return;
  dropdown.addEventListener('click', () => {
    const wrapper = document.querySelector('.dropdown-menu-custom');
    if (!wrapper) return;
    wrapper.classList.toggle('show-custom');
    if (!active) setTimeout(() => document.addEventListener('click',  outOfDropdown), 16);
    active = true;
  })
}



const outOfDropdown = (e) => {
  const _wrapper = document.querySelector('.dropdown-menu-custom');
  if (_wrapper && _wrapper.classList.contains('show-custom')) {
    const pos = _wrapper.getBoundingClientRect();
    if ((e.pageX < pos.left) || (e.pageX > pos.right) ||(e.pageY > pos.bottom) || (e.pageY < pos.top)) {
      _wrapper.classList.remove('show-custom');
      document.removeEventListener('click', outOfDropdown);
      active = false
    }
  }
};

const copy = () => {
  document.querySelectorAll('.copy').forEach(copy =>
    copy.addEventListener('click', function(event) {
      navigator.clipboard.writeText(copy.dataset.copy)
      mousePopup('Скопировано', event);
    } )
  )
}

const throttleHrefs = () => {
  const links = document.querySelectorAll('a');
  links.forEach(link => {
    link.addEventListener('click', e => {
      if (link.classList.contains('clicked')) {
        e.preventDefault();
        return false;
      } else {
        link.classList.add('clicked');
        setTimeout(() => link.classList.remove('clicked'), 2000)
      }
    })
  })
}

const ultraLightSpeedButtonDisabler3000 = () => {
  const forms = document.querySelectorAll('form');
  forms.forEach(form => {
    form.addEventListener('submit', () => form.querySelector('button').disabled = true)
  });
}

const urlToggle = () => {
  let accounts = document.querySelectorAll('a.card__wrapper');
  if (accounts.length === 0)  {
      accounts = accounts = document.querySelectorAll('div.card__wrapper');
      if (accounts.length === 0) return;
  }

  accounts.forEach(account => {
    account.href = account.getAttribute('data-href');
  })

  const loader = document.querySelectorAll('.card__wrapper .modal__loader-enabled');
  if (loader.length === 0) return;

  loader.forEach(item => {
    item.classList.remove('modal__loader-enabled');
    item.classList.add('modal__loader');
  });

  accounts.forEach(account => {
    account.addEventListener('click', (e) => {
      const loader = account.querySelectorAll('.modal__loader');
      loader.forEach(item => {
        item.classList.add('modal__loader-enabled');
        item.classList.remove('modal__loader');
      });
    })
  });

};

const shopAcquiring = () => {
  const body = document.querySelector("body.shop");
  const demo = document.querySelector('body.shop-demo-ver1');
  if (demo) return shopDemo();
  if (!body) return;
  const wrappers = body?.querySelectorAll(".visibility-toggle");
  if (wrappers) {
    [...wrappers].forEach(wrapper => {
      wrapper.addEventListener('click', () => {
        const true_wallet = wrapper.querySelector('.true-wallet');
        const fake_wallet = wrapper.querySelector('.fake-wallet');
        const icon = wrapper.querySelector('.icon__wrapper');
        true_wallet.classList.toggle('d-none');
        fake_wallet.classList.toggle('d-none');
        icon.classList.toggle('icon-show');
        icon.classList.toggle('icon-hide');
      })
    })
  }

  if (window.location.href.indexOf('?err=') !== -1) {
    hideModal(document.querySelector('.blur-portal__sms-confirm'))
  }
}

const shopDemo = () => {
  const controlWrapper = document.querySelector('.count-control');
  if (!controlWrapper) return;
  const output = controlWrapper.querySelector('.count');
  const outputHidden = controlWrapper.querySelector('input.count');
  const plus = controlWrapper.querySelector('.plus');
  const minus = controlWrapper.querySelector('.minus');
  const price = +document.querySelector('.heading .h2 span')?.innerText.replaceAll(' ', '');
  const priceOutput = document.querySelector('.description-price-wrapper .price');
  plus.addEventListener('click', () => {
    const hiddenOutput = document.querySelector('.description-price-wrapper .hidden-sum')
    outputHidden.value++;
    output.style.width = outputHidden.value.length * 13 + 'px';
    output.innerText = outputHidden.value;
    priceOutput.innerText = reverseString(space(price * outputHidden.value + '')) + ' ₽';
    hiddenOutput.value = price * output.value * 100 + '';
  })
  minus.addEventListener('click', () => {
    const hiddenOutput = document.querySelector('.description-price-wrapper .hidden-sum')
    if (outputHidden.value > 1) {
      outputHidden.value--;
      output.style.width = outputHidden.value.length * 13 + 'px';
      output.innerText = outputHidden.value;
      priceOutput.innerText = reverseString(space(price * outputHidden.value + '')) + ' ₽';
      hiddenOutput.value = price * output.value * 100 + '';
    }
  })
}

const reverseString = (str) => (str.split("").reverse().join(""));

const space = (string) => {
  // defaults to a space after 4 characters:
  const after = 3;

  /* removes all characters in the value that aren't a number,
     or in the range from A to Z (uppercase): */
  const v = reverseString(string),
      /* creating the regular expression, to allow for the 'after' variable
         to be used/changed: */
      reg = new RegExp(".{" + after + "}","g")
  return v.replace(reg, function (a, b, c) {
    return a + ' ';
  });
}

export function showModal(modal) {
  if (!modal) return;
  modal.classList.add("active");
  setTimeout(() => modal.classList.add("animate"), 100);
}
export function hideModal(modal) {
  if (!modal) return;
  modal.classList.remove("animate");
  setTimeout(() => modal.classList.remove("active"), 400);
}
/**
 * Функция для показа/скрытия тоаста.
 *  @param {string} query -классовый селектор.
 *  @return null;
 * */
export function toastToggle(query) {
  const toast = $(query);
  setTimeout(() => toast.show(), 100);
  const delayTimeout = +toast.data("delay");
  setTimeout(() => toast.hide(), delayTimeout);
}

export function scrollButtons() {

  const buttonRight = document.querySelector('.scroll-right');
  const buttonLeft = document.querySelector('.scroll-left');
  const scrollContainer = document.querySelector('.tariff-scroller');
  const tariffCardLength = document.querySelectorAll('.tariff-card').length;
  if (!scrollContainer) return;
  const scrollSize = scrollContainer.scrollWidth / tariffCardLength;

  scrollContainer.addEventListener('scroll', function () {

    let scrollRest = scrollContainer.scrollWidth - scrollContainer.scrollLeft;

    // Scroll is END
    if (scrollRest <= 1092) {
      buttonRight.classList.remove('active');
      buttonLeft.classList.add('active');
    }
    // Scroll is START
    else if (scrollRest === scrollContainer.scrollWidth) {
      buttonLeft.classList.remove('active');
      buttonRight.classList.add('active');
    } else {
      buttonRight.classList.add('active');
      buttonLeft.classList.add('active');
    }

  })


buttonRight.onclick = function () {

    scrollContainer.scroll({behavior: 'smooth', left: (scrollContainer.scrollLeft + scrollSize)});

};

  buttonLeft.onclick = function () {

      scrollContainer.scroll({behavior: 'smooth', left: scrollContainer.scrollLeft - scrollSize});

  };

}