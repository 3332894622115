import api from "../../compenents/ApiController";
import { MAIL_MASK } from "../../variables";
import iMask from "imask";

import {
    inputDebounced,
    mailValidate,
    modalLoadingBlurDescription,
    inlineInputAddHint,
    inlineInputRemoveHint,
} from "../../helpers";

import $ from 'jquery';

const config = window.configCrypt;

const web3Component = new window.web3Component(config);

const connectButton = document.querySelector('#wallet_connect');

const shopPage = async () => {
    if (location.pathname.includes('/web/')) return;
    const wrapper = document.querySelector('.shop_acquiring');

    if (wrapper && location.pathname.includes('/shop/')) {
        const isConnected = window.web3Component.isConnected();
        if (isConnected) {
            auto_connect();
        }

        btnHideLoader();

        manual_connect();
        buy();
        disconnectButton();
        changeSwitch();
    }
}

const toastAutoShow = async (type, text) => {
    const toastHolder = document.querySelector('.no-auto-toast');
    if (toastHolder, type.length === 0, text.length === 0) return;
    const toast = $(".toast");
    const toastHtml = document.querySelector('.toast')
    const toastText = toastHtml.querySelector('.text')
    toastHtml.classList.add(`toast__${type}`)
    toastText.innerHTML = text;
    setTimeout(() => toast.show(), 100);
    const delayTimeout = +toast.data("delay");
    setTimeout(() => {
        toast.hide()
        toastHtml.classList.remove(`toast__${type}`);
    }, 4000);
}

const disconnectButton = () => {
    const wrapper = document.querySelector('.usdt__section');
    if (wrapper) {
        const btnDisconnect = document.querySelector('#disconnectWallet');

        btnDisconnect.addEventListener('click', async function () {
            disconnect();
        });
    }
}

const btnHideLoader = () => {
    const btnHide = document.querySelector('#hide_loader');

    btnHide.addEventListener('click', function () {
        hideLoader();
    })
}

const buy = async () => {
    const wrapper = document.querySelector('.usdt__section');
    if (wrapper) {

        const buyBtn = document.querySelector('#buy_btn');

        const dataContainer = document.querySelector('#orderInfoData');

        const sum =  dataContainer.dataset.order_sum;
        const toCryptAddress = dataContainer.dataset.crypt_address;
        const order_id = dataContainer.dataset.order_id;
        const _sum = `${sum}0000000000000000`;

        const inputWrapper = document.querySelector("#input-email-usdt-js");
        const mailInput = inputWrapper.querySelector(".reg-email-input");

        mailInput?.addEventListener("input", () => {
            inlineInputRemoveHint(inputWrapper);
        });

        iMask(mailInput, MAIL_MASK);

        mailInputValidate(mailInput, inputWrapper);


        buyBtn.addEventListener('click', async function () {
            const emailValid = mailValidate(mailInput?.value);
            if (emailValid || mailInput.value.length === 0) {
                modalLoadingBlurDescription();
                const crypt_from = await web3Component.getAccount();

                const transfer = await web3Component.transferW2W(toCryptAddress, _sum).catch((err) => {
                    hideLoader();
                    if (err.message.startsWith('User rejected the transaction')) {
                        toastAutoShow('error', 'Операция отменена пользователем')
                    } else {
                        toastAutoShow('error', 'Произошла непредвиденная ошибка. Попробуйте ещё раз')
                    }
                    console.log('Error', err)

                });

                if (transfer.transactionHash) {
                    setOrderCrypt(order_id, crypt_from, mailInput.value, transfer.transactionHash);
                } else {
                    window.location.replace(`${document.location.origin}/shop/error`);
                }
            }
        })
    }

}

const mailInputValidate = (input, wrapper) => {
    inputDebounced(input, () => {
        const { value } = input;
        const isValid = mailValidate(value);
        if (isValid || value?.length === 0) {
            inlineInputRemoveHint(wrapper)
        } else {
            inlineInputAddHint(wrapper, "Неверная почта")
        }
    });
};

const setOrderCrypt = async (order_id, crypt_from, email, transactionHash) => {
    try {
        const body = {
            'order_id': order_id,
            'crypt_from': crypt_from,
            'email': email,
            'transaction_hash': transactionHash,
        };

        const res = await api.post({ href: '/shop/setOrderCrypt', body })
        if (res?.transaction_hash.length > 0) {
            window.location.replace(`${document.location.origin}/shop/finish`);
        } else {
            window.location.replace(`${document.location.origin}/shop/error`);
        }
    } catch (e) {
        console.log('ERROR: setOrderCrypt', e);
    }
}

const disconnect = async () => {
    await web3Component.disconnectWallet();
    goToConnectScreen();
}

const manual_connect = async () => {
    if (!connectButton) return;
    connectButton.addEventListener('click', async () => {
        const isConnected = window.web3Component.isConnected();
        if (isConnected) return;
        web3Component.connectWallet().then(async () => {
            toastAutoShow('success', 'Кошелёк подключен')
            goToConnectedScreen();
        }).catch((e) => {
            if (e.message.startsWith('User closed modal')) {
                toastAutoShow('error', 'Операция отменена пользователем')
            } else {
                toastAutoShow('error', 'Произошла непредвиденная ошибка. Попробуйте ещё раз')
            }
            console.error(e);
        });
    });
}

const auto_connect = async () => {
    web3Component.connectWallet().then(async () => {
        goToConnectedScreen();
    }).catch((e) => {
        if (e.message.startsWith('User closed modal')) {
            toastAutoShow('error', 'Операция отменена пользователем')
        } else {
            toastAutoShow('error', 'Произошла непредвиденная ошибка. Попробуйте ещё раз')
        }
        console.error(e);
    })
}


const goToConnectedScreen = async () => {
    const wrapper = document.querySelector('.usdt__section');
    if (wrapper) {
        const connectContainer = document.querySelector('#connect-container');
        const connectedContainer = document.querySelector('#connected-container');
        const wallet_sum = await web3Component.getTokenBalance(config.USDT_ADDRESS);
        const wallet_address = await web3Component.getAccount();
        const walletAddressInput = document.querySelector('#wallet_address');
        const walletSumInput = document.querySelector('#wallet_sum');

        walletAddressInput.value = wallet_address.substring(0, 6) + '...' + wallet_address.substring(wallet_address.length - 5);
        walletSumInput.innerText = wallet_sum + ' USDT';

        connectContainer.classList.add('d-none');
        connectedContainer.classList.remove('d-none')
    }
}

const goToConnectScreen = async () => {
    const connectContainer = document.querySelector('#connect-container');
    const connectedContainer = document.querySelector('#connected-container');

    connectContainer.classList.remove('d-none');
    connectedContainer.classList.add('d-none')
}



const changeSwitch = () => {
    const wrapper = document.querySelector('.switch-section .switch');
    if (!wrapper) return;
    else wrapper.classList.remove('d-none');

    const bg = wrapper.querySelector('.switch__bg');
    /*bg.style.right = '41.7245%';*/
    const switchItem = wrapper.querySelectorAll('.switch__item');


    switchItem.forEach((item, key) => {
        item.addEventListener('click', () => {
            if (switchItem.length === 3) {
                changeSwitchItemThreeElem(item, key)
            } else {
                changeSwitchItem(item, key);
            }

        })
    })
}

const changeSwitchItem = (item, key) => {
    const wrapper = document.querySelector('.switch-section .switch');
    if (!wrapper) return;
    const switchItem = wrapper.querySelectorAll('.switch__item');
    const bg = wrapper.querySelector('.switch__bg');

    const cardSection = document.querySelector('.card__section')
    const walletSection = document.querySelector('.wallet__section')

    const switchItemsOnePercent = wrapper.clientWidth / 100;

    const bgRight = switchItem[0].clientWidth / switchItemsOnePercent;
    const bgLeft = switchItem[1].clientWidth / switchItemsOnePercent;

    const bgFreeSpace = 100 - (bgRight + bgLeft);

    const bgRightWithoutSpace = bgRight - bgFreeSpace;
    const bgLeftWithoutSpace = bgLeft - bgFreeSpace;

    if (item.classList.contains('switch__item-active')) return;
    if (key === 0) { //wallet
        switchItem[1].classList.remove('switch__item-active');
        switchItem[0].classList.add('switch__item-active');
        bg.style.left = 0;
        bg.style.right = `${bgLeftWithoutSpace - 1}%`;
        cardSection.classList.remove('d-none')
        walletSection.classList.add('d-none');
    } else { //bsc
        switchItem[0].classList.remove('switch__item-active');
        switchItem[1].classList.add('switch__item-active');
        bg.style.right = 0;
        bg.style.left = `${bgRightWithoutSpace - 1}%`;
        cardSection.classList.add('d-none')
        walletSection.classList.remove('d-none');

    }
}

const changeSwitchItemThreeElem = (item, key) => {
    const wrapper = document.querySelector('.switch-section .switch');
    if (!wrapper) return;
    const switchItem = wrapper.querySelectorAll('.switch__item');
    const bg = wrapper.querySelector('.switch__bg');

    const cardSection = document.querySelector('.card__section')
    const walletSection = document.querySelector('.wallet__section')
    const usdtSection = document.querySelector('.usdt__section');

    const switchItemsOnePercent = wrapper.clientWidth / 100;

    const bgLeft = switchItem[0].clientWidth / switchItemsOnePercent;
    const bgCenter = switchItem[1].clientWidth / switchItemsOnePercent;
    const bgRight = switchItem[2].clientWidth / switchItemsOnePercent;

    const bgFreeSpace = 100 - (bgRight + bgLeft + bgCenter);

    const bgCenterWithoutSpace = bgCenter - bgFreeSpace
    const bgRightWithoutSpace = (bgLeft + bgCenter) - bgFreeSpace;
    const bgLeftWithoutSpace = (bgRight + bgCenter) - bgFreeSpace;

    const bgCenterLeftWithoutSpace = bgLeft - bgFreeSpace;
    const bgCenterRightWithoutSpace = bgRight - bgFreeSpace;

    if (item.classList.contains('switch__item-active')) return;
    if (key === 0) { //wallet
        switchItem[2].classList.remove('switch__item-active');
        switchItem[1].classList.remove('switch__item-active');
        switchItem[0].classList.add('switch__item-active');
        bg.style.left = 0;
        bg.style.right = `${bgLeftWithoutSpace - 1}%`;
        cardSection.classList.remove('d-none')
        walletSection.classList.add('d-none');
        usdtSection.classList.add('d-none')
    } else if (key === 1) { //usd
        switchItem[2].classList.remove('switch__item-active');
        switchItem[1].classList.add('switch__item-active');
        switchItem[0].classList.remove('switch__item-active');

        bg.style.right = `${bgCenterRightWithoutSpace - 1}%`;
        bg.style.left = `${bgCenterLeftWithoutSpace - 1}%`;
        cardSection.classList.add('d-none')
        walletSection.classList.remove('d-none');
        usdtSection.classList.add('d-none')

    } else { // USDT
        switchItem[2].classList.add('switch__item-active');
        switchItem[1].classList.remove('switch__item-active');
        switchItem[0].classList.remove('switch__item-active');

        bg.style.right = 0;
        bg.style.left = `${bgRightWithoutSpace - 1}%`;
        cardSection.classList.add('d-none')
        walletSection.classList.add('d-none');
        usdtSection.classList.remove('d-none')
    }
}

function hideLoader() {
    const modal = document.querySelector(".modal__loader");
    const modalBody = modal.querySelector(".modal__loader_description");
    modalBody.classList.add('d-none');
    modal?.classList.remove("modal__loader_blur-enabled");
}

export default shopPage;