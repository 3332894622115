import {CODE_INITIAL_TIMER, MAX_CODE_RETRY} from "../variables";
import {setTimer} from "../helpers";
import Api from './ApiController';


class CodeResend {
    constructor(wrapperQuery, timerQuery, time, query, retryCount) {
        this.wrapperQuery = wrapperQuery || ".timer-wrapper";
        this.timerQuery = timerQuery || ".timer-text";
        this.getWrapper();
        this.getTimer();
        this.time = time || CODE_INITIAL_TIMER;
        this.query = query || '.code-timer';
        this.api = Api;
        this.pressed = false;
        this.retryCount = retryCount ||MAX_CODE_RETRY
    }

    runTimer() {
        if (!this.timer) return console.error('no timer-text');
        if (!this.query) return console.error('no query');
        setTimer(this.time, this.query, () => this.resendCode())
    }

    getTimer() {
        this.timer = document.querySelector(this.timerQuery);
    }

    getWrapper() {
        this.wrapper = document.querySelector(this.wrapperQuery)
    }

    setTimer(time, query, callback, interval = 1000) {
        const endDate = new Date();
        endDate.setSeconds(endDate.getSeconds() + time);
        const timerQuery = document.querySelector(query);
        if (timerQuery) {
            const id = setInterval(() => timer(), interval);
            const timer = () => {
                const time = ((new Date(endDate) - new Date()) / 1000).toFixed(0);
                timerQuery.innerHTML = `${time} сек.`;
                if (time <= 0) {
                    clearInterval(id);
                    callback();
                }
            };
        }
    }

    resendCode() {
        const link = this.generateLink();
        this.getWrapper()
        this.getTimer()
        this.wrapper.replaceChild(link, this.timer);
        const hint = this.generateHint();
        this.pressed = false;
        link.addEventListener("click", async (e) => {
            if (this.pressed) {
                console.log('pressed', this.pressed);
                return e.preventDefault();
            }
            this.pressed = true;
            if (this.retryCount === 0) {
                console.log('count', this.retryCount)
                return e.preventDefault();
            }
            this.retryCount--;
            this.getWrapper();
            const body = {type: this.getCurrentSmsType()}
            const loader = this.generateLoader();
            this.wrapper.appendChild(loader);
            await this.api.post({href: '/resendCode', body});
            loader.remove();
            this.wrapper.replaceChild(hint, link);
            this.setTimer(this.time, this.query, () => this.resendCode());
        })
    }

    generateLoader() {
        const wrapper = document.createElement("div");
        wrapper.classList.add('icon__wrapper', 'loader__wrapper');
        const modal_loader = document.createElement("div");
        modal_loader.classList.add('modal__loader',
            'modal__loader-small', 'loader-visible', 'loader-enabled');
        const loader = document.createElement("div");
        loader.classList.add("loader");
        modal_loader.appendChild(loader);
        wrapper.appendChild(modal_loader);
        return wrapper;
    }

    getCurrentSmsType () {
        let url = window.location.pathname
            .split('/actions/');
        if (url.length === 1) {
            url = window.location.pathname
                .split('/action/')
        }
        if (url[1]) return url[1].replace('/sms', '');
        return 'login';
    }

    generateHint () {
        const hint = document.createElement("p");
        const hint_timer = document.createElement("span");
        hint.classList.add("text-2", "text-center", "timer-text");
        hint_timer.classList.add("text-2-bold", "code-timer");
        hint.appendChild(document.createTextNode("Сможем отправить код повторно"));
        hint.appendChild(document.createElement("br"));
        hint.appendChild(document.createTextNode("через "));
        hint_timer.appendChild(document.createTextNode(`${this.time - 1} сек.`));
        hint.appendChild(hint_timer);
        return hint;
    }

    generateLink() {
        const link = document.createElement('a');
        link.classList.add("text-2", "common__link", "code_resend");
        link.appendChild(document.createTextNode("Выслать код повторно"));
        return link;
    }
}

export default CodeResend;