import iMask from "imask";
import {
    CODE_INITIAL_TIMER,
    CODE_MASK
} from "../../variables";
import {addErrorHint, br, isValid, modalLoading, setTimer} from "../../helpers";
import CodeResend from "../../compenents/CodeResend";

let needToStop = false;
let timerJS;
let submitted = false;

const codePage = () => {
  const codeInput = document.querySelector(".code-input");
  if (codeInput) {
    const mask = iMask(codeInput, CODE_MASK);
    codeInput.addEventListener("input", () => {
      if (isValid(codeInput.value, 6)) {
        if (submitted) return;
        modalLoading();
        if (!document.querySelector('.no-code-submit')) document.getElementsByTagName("form")[0]?.submit();
        submitted = true;
      }
    });
    const codeTimerOutput = document.querySelector(".code-timer");
    if (codeTimerOutput) {
      const codeResend = new CodeResend();
      codeResend.runTimer();
      // const timeWrapper = document.querySelector(".timer-wrapper");
      // const timer = document.querySelector(".timer-text");
      //   timerJS = setTimer(CODE_INITIAL_TIMER, ".code-timer", () => resendCode(timeWrapper, timer));
    }
    const button = document.getElementById("no_code");
    button?.addEventListener("click", () => {
      const hint = document.querySelector(".code-problem-hint");
      hint?.classList?.toggle("d-none");
    });
  }
};


//
export default codePage;
